@import 'base/variables';
@import 'vendors/normalize';
@import 'base/reset';
@import 'base/forms';

a {
  color: $base-link-color;
}

.main-container {
  max-width: 1100px;
  padding: 0 20px 40px;
  margin: 0 auto;
}