.file-upload {
  img, video {
    width: 160px;
  }

  .label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 0 12px;

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      opacity: 0;
    }
  }

  .input-zone {
    display: block;
    width: 100%;
    height: 120px;
    background: #f1f1f1;
    border: 1px dotted #000;
  }
}