.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  .actions {
    display: flex;
  }

  .logo {
    display: block;
    padding: 0 0 15px;
    img {
      height: 40px;
    }
  }

  nav a {
    margin: 0 15px 0 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .MuiAvatar-root {
    margin-left: 15px;
  }
}
