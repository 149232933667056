.date-range {
  position: relative;

  .picker {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;

    .react-datepicker {
      button.react-datepicker__navigation {
        min-width: inherit;
        min-height: inherit;
        border-radius: 0;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}
