.user-data {
  label {
    display: inline-block;
  }

  .info {
    margin-left: 1rem;

    p:last-child {
      padding: 0.5rem 0;
      text-decoration: underline;
    }
  }
}
