@import '../../assets/scss/vendors/include-media';

.zapier {
  padding: 10px 0 40px;
}

.subtitle {
  font-size: 20px;
  color: #aaa;
  margin: 20px 0 50px;
}

