// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #B5B5B5;
$mercury: #E6E6E6;
$wild-sand: #F4F4F4;
$pampas: #F9F8F6;
$black: #37383D;
$macko: #42444E;
$white: #fff;
$darken-red: #8B000E;
$red: #DC2B2A;
$dark: #0E1719;
$green: #2E7D32;

$base-font-sans-serif: Helvetica, Arial, 'Lato', sans-serif;
$base-font-serif: 'Merriweather', serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $black !default;
$base-background-color: $white !default;
$font-size-base: 18px !default;
$line-height-base: 1.2 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $base-text-color;
$base-link-hover-color: $darken-red;

// Buttons 
$btn-color: $white !default;
$btn-background: $red !default;
$btn-background-hover: $darken-red!default;
$btn-padding: 10px 20px !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: 10px !default;
$form-element-border-color: #ccc !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
//$h1-font-size: 30px !default;
//$h2-font-size: 27px !default;
//$h3-font-size: 24px !default;
//$h4-font-size: 21px !default;
//$h5-font-size: 17px !default;
//$h6-font-size: 15px !default;

$headings-font-family: $base-font-serif !default;
$headings-color: inherit !default;


$animation-speed: 0.3s;
