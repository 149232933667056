@import '../../assets/scss/vendors/include-media';

.reports {
  padding: 10px 0 40px;

  section {
    margin-top: 2rem;
  }
}

.subtitle {
  font-size: 20px;
  color: #aaa;
  margin: 20px 0 50px;
}
