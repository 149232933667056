@import '../../assets/scss/vendors/include-media';

.content-edit {
  form {
    input[type='text'], input[type='number'] {
      width: 100%;
      margin: 0 0 20px;
    }

    input[type='file'] {
      margin: 0 0 20px;
    }
  }

  .msg {
    margin-top: 0;
  }

  .priority {
    margin-bottom: 30px;

    .msg {
      margin-top: 15px;
    }
  }

  textarea {
    margin: 0 0 20px;
  }

  .preview {
    position: relative;

    video {
      position: relative;
      z-index: 1;
      width: 100% !important;
      margin-bottom: 20px;
    }

    button {
      position: absolute;
      z-index: 2;
      top: 10px;
      right: 10px;
      margin: 0;
      min-width: 0;
    }
  }

  .file-upload {
    label + span,
    button {
      margin-left: 30px;
    }

    @include media('>=tablet') {
      text-align: center;
    }
  }

  .upload-block {
    padding: 0 0 20px;
    .row {
      @include media('>=tablet') {
        margin: 0 -20px;
        display: flex;
        align-items: flex-start;
      }
    }

    .file-upload {
      @include media('>=tablet') {
        padding: 0 20px;
        width: 33.3%;

      }
    }
  }
}
