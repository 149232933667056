@import '../../assets/scss/vendors/include-media';

.dashboard {
  padding: 10px 0 40px;
}

.subtitle {
  font-size: 20px;
  color: #aaa;
  margin: 20px 0 50px;
}

.infos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 30px;
  .item {
    width: 45%;
    text-align: center;
    padding: 0 0 35px;

    @include media('>=desktop') {
      width: 20%;
    }
  }

  .num {
    display: block;
    font-size: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin: 0 0 10px;
  }

  .txt {
    display: block;
  }
}