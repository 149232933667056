.grid {
  display: grid;

  &.grid-columns-2 {
    grid-template-columns: 1fr 1fr;
  }
}

label {
  margin: 0 2rem 2rem 0;

  > div {
    font-weight:    bold;
    margin-bottom:  0.5rem;
  }

  > input {
    width: 100%;
  }
}
