.dialog-enter {
  opacity: 0;
  visibility: hidden;
}

.dialog-enter .inner-area {
  opacity: 0;
  transform: scale(.8);
}

.dialog-enter-active {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease-out, visibility .3s ease-out;
}

.dialog-enter-active .inner-area {
  opacity: 1;
  transform: scale(1);
  transition: opacity .2s ease-out .3s, transform .2s ease-out .3s;
}

.dialog-exit {
  opacity: 1;
  visibility: visible;
}

.dialog-exit .inner-area {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-out, visibility .3s ease-out;
}

.dialog-exit-active .inner-area {
  opacity: 0;
  transform: scale(.8);
  transition: opacity .2s ease-out .3s, transform .2s ease-out .3s;
}

.dialog-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  white-space: nowrap
}

.dialog-window:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 1px;
  overflow: hidden;
  margin: 0 0 0 -5px
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: .4
}

.dialog-window .scroll-area {
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  overflow: auto;
  max-height: 100%
}

.dialog-window .indent-area {
  padding: 45px
}

.dialog-window .inner-area {
  position: relative;
  z-index: 2;
  background: #fff;
  box-shadow: 0 10px 38px 0 rgba(0, 0, 0, .33);
  padding: 50px;
  max-width: 750px;
  margin: 0 auto;
}

.dialog-window .text-holder {
  margin: 0 auto;
  max-width: 630px;
  color: #333
}

.dialog-window h2 {
  color: #333;
  margin: 0 0 30px;
  font-size: 20px
}

.dialog-window h2 a {
  text-decoration: none
}

.dialog-window h2 a:hover {
  text-decoration: underline
}

.dialog-window .popup-btn {
  display: inline-block;
  vertical-align: top;
  padding: 17px 10px;
  border-radius: 4px;
  font-weight: 800;
  border: 1px solid #b6c3ca;
  background: #fff;
  color: #414141;
  transition: background .5s ease;
  min-width: 263px;
  margin: 0 6px;
  text-decoration: none
}

.dialog-window .popup-btn:hover {
  background: #f5f5f5
}

.dialog-window .popup-btn.btn-blue {
  border-color: #0086da;
  background: #0086da;
  color: #fff
}

.dialog-window .popup-btn.btn-blue:hover {
  background: #0096f4
}

.dialog-window .close {
  position: absolute;
  top: 26px;
  right: 35px;
  width: 20px;
  z-index: 20;
  height: 20px;
  cursor: pointer;
}

.dialog-window .close:hover:after,
.dialog-window .close:hover:before {
  background: #676767
}

.dialog-window .close:after,
.dialog-window .close:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 3px;
  width: 15px;
  height: 3px;
  background: #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.dialog-window .close:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

@media(max-width: 1023px) {
  .dialog-window .close {
    top: 13px;
    right: 13px
  }
  .dialog-window .indent-area {
    padding: 10px
  }
  .dialog-window .inner-area {
    padding: 40px 15px;
    box-shadow: none
  }
}

@media(max-width: 767px) {
  .dialog-window .popup-btn {
    min-width: 0;
    margin: 0;
    width: 100%;
    max-width: none
  }
  .dialog-window .popup-btn + .popup-btn {
    margin: 15px 0 0
  }
}

.withdraw-popup .withdraw-close {
  position: absolute;
  top: 26px;
  right: 35px;
  width: 20px;
  height: 20px
}

.dialog-window .close:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 3px;
  width: 15px;
  height: 3px;
  background: #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.dialog-window .close:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

@media(max-width: 1023px) {
  .dialog-window .close {
    top: 13px;
    right: 13px
  }
  .dialog-window .indent-area {
    padding: 10px
  }
  .dialog-window .inner-area {
    padding: 40px 15px;
    box-shadow: none
  }
}

.dialog-window .popup-btn,
.dialog-window button {
  display: inline-flex;
  margin-top: 15px;

  @media(max-width: 767px) {
    min-width: 0;
    margin: 0;
    width: 100%;
    max-width: none;
  }

  & + .popup-btn,
  & + button {
    margin-left: 15px;
  }
}

