@import '../vendors/include-media';

// forms
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
//input[type='url'],
input[type='date'],
textarea {
	-webkit-appearance: none;
	border-radius: 10px;
	box-sizing: border-box;
	border: 1px solid $form-element-border-color;
	padding: $form-element-padding;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	background: $white;
	min-width: 400px;

	&:focus {
		outline: none;
		border-color: $form-element-focus-border-color;
	}
}

input[type='radio'] {
	display: inline-block;
	vertical-align: middle;
	width: 18px;
	height: 18px;
	border: 1px solid $form-element-border-color;
	margin: 5px 10px 5px 5px;
}

textarea {
	width: 100%;
	min-height: 160px;
}

select {
	-webkit-border-radius: 0;
	border-radius: 10px;
	box-sizing: border-box;
	border: 1px solid $form-element-border-color;
	padding: $form-element-padding;
	font-size: 16px;
}

textarea {
	resize: vertical;
	vertical-align: top;
}

a.button,
button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
	-webkit-appearance: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	background: $white;
	color: $black;
	font-size: 16px;
	line-height: 1;
	padding: $btn-padding;
	min-width: 80px;
	min-height: 38px;
	border-radius: 10px;
	border: 1px solid $form-element-border-color;

	&:hover {
		background: #ccc;
		color: $white;
		text-shadow: 1px 1px 2px #000;
	}

	&.btn-secondary {
		min-width: 155px;
		min-height: 50px;
		background: $white;
		color: $red;

		&:hover {
			box-shadow: inset 0 0 0 2px $darken-red;
			color: $darken-red;
		}
	}

	&.btn-white {
		box-shadow: inset 0 0 0 2px $white;
		color: $white;
		background: transparent;

		&:hover {
			box-shadow: inset 0 0 0 2px $gray;
			color: $gray;
		}
	}
}

input[type='checkbox'] {
	border: 1px solid $form-element-border-color;
	border-radius: 2px;
	width: 18px;
	height: 18px;
}

button + button {
	margin-left: 20px;
}

.chk-label {
	position: relative;
	overflow: hidden;
	display: inline-flex;
	vertical-align: middle;
	padding-left: 36px;
	font-size: 16px;
	line-height: 22px;
	margin: 0 0 39px;

	span {
		display: inline-flex;
		vertical-align: middle;
		position: relative;

		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: -36px;
			width: 22px;
			height: 22px;
			background: $red;
			border-radius: 3px;
		}

		&:after {
			position: absolute;
			content: "\e901";
			font-family: 'icomoon' !important;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			font-size: 12px;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: $white;
			top: 5px;
			left: -31px;
			display: none;
		}
	}

	input[type='checkbox'] {
		position: absolute;
		left: -100px;

		&:checked {
			& + span {
				&:after {
					display: block;
				}
			}
		}
	}
}

form {
	.txt {
		font-weight: 700;
	}

	ul {
		border-left: 3px solid $form-element-border-color;
		padding: 0 0 0 40px;
		list-style: none;
		margin: 0 0 40px;

		@include media('<tablet') {
			padding-left: 0;
			border-left: none;
		}

		> li {
			padding: 15px 0 40px;

			.row {
				display: flex;
				justify-content: space-between;
			}

			input[type='text'] {
				width: 60%;

				@include media('<tablet') {
					width: 100%;
				}
			}

			select {
				width: 35%;
			}

		}

		ul {
			border: none;
			margin: 0;
			padding: 15px 0 15px;

			@include media('<tablet') {
				padding-left: 0;
			}

			li {
				display: flex;
				align-items: center;
				padding: 15px 0;

				input[type='text'] {
					width: 55%;
					margin-right: 5%;
				}
			}
		}

		.radio {
			display: inline-flex;
			vertical-align: middle;
			width: 5%;

			input[type='radio'] {
				margin-right: 0;
			}

			@include media('<tablet') {
				width: auto;
				min-width: 40px;
			}
		}
	}

	button,
	.button {
		line-height: 1.2;
	}

	.ttl {
		display: block;
		font-weight: 700;
		padding: 40px 0 25px;
	}


	.btns {
		padding-top: 40px;

		button,
		.button {
			display: inline-flex;
			line-height: 1.2;
			margin: 0 20px 20px 0;
			text-decoration: none;
		}
	}

	ul.chk-list {
		padding: 0;
		border: none;

		li {
			padding: 15px 0 20px;
		}

		label {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		.txt {
			margin-left: 15px;
		}
	}

	.radios {
		display: flex;
		max-width: 600px;
		justify-content: space-between;
		align-items: center;

		span,
		input {
			margin-right: 15px;
		}
	}
}

.error {
	input[type='text'],
	input[type='tel'],
	input[type='email'],
	input[type='search'],
	input[type='password'],
	input[type='date'],
	textarea {
		border-color: $red;
	}
}

.msg {
	font-size: 14px;
	color: $red;

	&.success {
		color: $green;
	}
}
